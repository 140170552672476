import { useSnackbar } from 'notistack'
import useLogout from '~/src/hooks/auth/useLogout'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { deleteCustomer } from '~/src/services/CustomerService'
import { CUSTOMER_STATE } from '~/src/stores/customer'

interface ReturnsType {
  doDeleteCustomer(): void,
}

const useDeleteCustomer = (): ReturnsType => {
  const { t } = useTranslations()
  const customer = useEoValue(CUSTOMER_STATE)
  const { logout } = useLogout()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { enqueueSnackbar } = useSnackbar()

  const doDeleteCustomer = (): void => {
    if (!customer?.uid || !customer?.id) {
      return
    }

    deleteCustomer(customer.uid, customer.id).then(() => {
      enqueueSnackbar({
        message: t('profile.account.deletedSuccess'),
        variant: 'success'
      })
      logout()
      void push(resolve(RoutesEnum.LOGIN))
    })
  }

  return { doDeleteCustomer }
}

export default useDeleteCustomer
